import {React, useState} from 'react';
import ReactGA from "react-ga4";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

// import machineData from "./data/data.json";
import osm from "./components/osm-provider"
import Markers from './components/markers';
import AddNewMachine from './components/form/AddNewMachine';
import './App.css';

import Row from 'react-bootstrap/Row';

const CENTER = { lat: 50.93636708374751, lng: 4.363266506150782 }

const TRACKING_ID = "G-TKZV217338";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");


// handler to log to console the click event
// onClick={event => handleClickEvent(event, 'rrr')}
const handleClickEvent = (e, param) => {
  // console.log(e);
  console.log(param);
};

export default function App(){
  const ZOOM_LEVEL = 14;
  const [center, setCenter] = useState(CENTER);
  return (
    <div className="container-fluid p-0">
      <Row className="justify-content-center"><AddNewMachine zoom={ZOOM_LEVEL} center={center}/></Row>
      <MapContainer center={center} zoom={ZOOM_LEVEL} id="main">
        <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution} />
        <MarkerClusterGroup showCoverageOnHover={false} chunkedLoading > 
        <Markers />
        </MarkerClusterGroup>)
      </MapContainer>
    </div>
  );
}

