import {React, useState, useRef, useMemo} from 'react';
import uuid from 'react-uuid'
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import {Icon} from "leaflet";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import osm from "../osm-provider";

const CENTER = { lat: 50.93636708374751, lng: 4.363266506150782 }
export const markericon = new Icon({iconUrl: "./marker-icon.png"});

function DraggableMarker(props) {
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          props.setMarkerPosition(marker.getLatLng())
          // handleClickEvent(marker.getLatLng(), 'move mouse')
        }
      },
    }),
    [],
  )
  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={props.markerPosition}
      ref={markerRef}
      icon={markericon}>
    </Marker>
  )
}


const AddNewMachine = (props) => {
  const [markerPosition, setMarkerPosition] = useState(CENTER)
  const [machineInfo, setMachineInfo] = useState({
    NAME: "",
    ADDRESS: "",
    ZIPCODE: "",
    EMAIL:""
  })
  const handleMachineInfoChange = (event) => {
    setMachineInfo({...machineInfo, [event.target.name]: event.target.value});
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(machineInfo);
    console.log(markerPosition);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    let data = {
      "type": "Feature",
      "verified": false,
      "properties": {
        "NAME":machineInfo.NAME,
        "ADDRESS" : machineInfo.ADDRESS,
        "ZIPCODE" : machineInfo.ZIPCODE,
        "FACILITYID": uuid(),
      },
      "geometry": {
        "type": "Point",
        "coordinates": [markerPosition['lng'], markerPosition['lat']]
      }
    }
    console.log(data)
    fetch("https://broodautomaat-com.vercel.app/api/list", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

    setMachineInfo({ NAME: "", ADDRESS: "", ZIPCODE: "", FACILITYID:"", EMAIL:""});
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Een automaat toevoegen
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Automaat toevoegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Locatie</h2>
          <div style={{height: '30vh'}}>
            <MapContainer 
              center={props.center}
              zoom={props.zoom}
              id="child"
              style={
                { height: "100%", minHeight: "100%" }
              }>
              <TileLayer
                url={osm.maptiler.url}
                attribution={osm.maptiler.attribution}
              />
              {/* The marker position position is passed to the components and state is stored at form level */}
              <DraggableMarker markerPosition={markerPosition} setMarkerPosition={setMarkerPosition}/>

            </MapContainer>
          </div>
          <h2>Extra informatie</h2>
          <Form>
          <Form.Group className="mb-3" controlId="formBasicStreename">
              <Form.Label>Naam</Form.Label>
              <Form.Control type="text" autoComplete="name" placeholder="Straat" name='NAME' value={machineInfo.NAME} onChange={handleMachineInfoChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicStreename">
              <Form.Label>Straatnaam</Form.Label>
              <Form.Control type="text" autoComplete="street-address" placeholder="Straat" name='ADDRESS' value={machineInfo.ADDRESS} onChange={handleMachineInfoChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formZIPCode">
              <Form.Label>ZIP code</Form.Label>
              <Form.Control type="text" placeholder="ZIP code" autoComplete='postal-code' name='ZIPCODE' value={machineInfo.ZIPCODE} onChange={handleMachineInfoChange}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email adres" name='EMAIL' value={machineInfo.EMAIL} onChange={handleMachineInfoChange}/>
            </Form.Group>
            <h2>Beschikbaar op dit automaat</h2>
            {['Brood', 'Vruchten en Groenten', 'Snacks'].map((type) => (
              <div key={`default-${type}`} className="mb-3">
                <Form.Check 
                  type="switch"
                  id={`default-${type}`}
                  label={`${type}`}
                />
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sluiten
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Toevoegen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// What I'm supposed to pass to DB is the following object:
//{
//   "type": "Feature",
//   "properties": {
//     "NAME":"",
//     "ADDRESS" : "",
//     "ZIPCODE" : "",
//     "FACILITYID": uuidv4(),
//   },
//   "geometry": {
//     "type": "Point",
//     "coordinates": [0, 0]
// }

export default AddNewMachine;