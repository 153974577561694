import {React, useState, useEffect} from 'react';
import ReactGA from "react-ga4";
import { Marker, Popup } from "react-leaflet";
import {Icon} from "leaflet";

const openInNewTab = (url) => {window.open(url, '_blank').focus();}; // handler to open link in new tab
export const icon = new Icon({iconUrl: "./break.png", iconSize: [25, 25]});
function Image(props) {return <img src={props.PICTURE} alt="machine" width="100" height="100"></img>;}
function DummyImage() {return <img src='./default.jpeg' alt="machine" width="100" height="100"></img>;}


const Markers = () => {
  const [data, setData] = useState(); 
  
  useEffect(() => {
    const dataFetch = async () => {
      const data = await (
        await fetch("https://broodautomaat-com.vercel.app/api/list")
      ).json();
      setData(data);
    };
    dataFetch();
  }, []);
  return (
   data && data.length > 0 && data.map((machine, index) => (
    <Marker
      key={machine.properties.FACILITYID}
      position={[
        machine.geometry.coordinates[1],
        machine.geometry.coordinates[0]
      ]}
      eventHandlers={{
        click: () => {
          ReactGA.event({ category: "Machine", action: "Open details", label: machine.properties.FACILITYID });
        }
      }}
      icon={icon}
  >
    <Popup>
      <div><h2>{machine.properties.NAME}</h2></div>
      <div><p>{machine.properties.ADDRESS}</p></div>
      <div><p>{machine.properties.DESCRIPTION}</p></div>
      <div>{machine.properties.PICTURE ? <Image PICTURE={machine.properties.PICTURE} /> : <DummyImage />}</div>
  
      <button 
        onClick={() => {
          openInNewTab('https://www.google.com/maps/dir//+' + machine.geometry.coordinates[1] + '+' + machine.geometry.coordinates[0]);
          ReactGA.event({ category: "Machine", action: "Directions", label: machine.properties.FACILITYID });
        }}>
          Open Map
      </button>
      
    </Popup>
  </Marker>
  )))
}

export default Markers;